export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wee"])},
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts:"])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199（Europe）"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241（Asia）"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (Asia - Singapore)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (Asia - China Region)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address:"])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en.sales", "@", "dooprime.com（Account Manager）"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en.support", "@", "dooprime.com（Technical Support）"])}
    },
    "menu": {
      "marketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
      "marketSecurities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities"])},
      "marketSecuritiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/securities"])},
      "marketFutures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
      "marketFuturesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/futures"])},
      "marketForex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forex"])},
      "marketForexUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/forex"])},
      "marketMetals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precious Metals"])},
      "marketMetalsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/precious-metal"])},
      "marketCommodities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commodities"])},
      "marketCommoditiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/commodities"])},
      "marketStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Indices"])},
      "marketStockUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/stock-indices"])},
      "toolsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "toolsPlatformTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Platform"])},
      "toolsPlatformMetaTrader4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTrader 4"])},
      "toolsPlatformMetaTrader4Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/metatrader4"])},
      "toolsPlatformMetaTrader5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTrader 5"])},
      "toolsPlatformMetaTrader5Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/metatrader5"])},
      "toolsPlatformMT4vsMT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MT4 vs MT5"])},
      "toolsPlatformMT4vsMT5Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/mt4-vs-mt5/"])},
      "toolsPlatformDooPrimeInTrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime InTradee"])},
      "toolsPlatformDooPrimeInTradeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/intrade"])},
      "toolsPlatformTradingView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TradingView"])},
      "toolsPlatformTradingViewUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/tradingview"])},
      "toolsPlatformFIXAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIX API 4.4"])},
      "toolsPlatformFIXAPIUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/fix-api"])},
      "toolsSocialTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Trading"])},
      "toolsSocialOutrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outrade"])},
      "toolsSocialOutradeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/outrade"])},
      "toolsSocialMyfxbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myfxbook"])},
      "toolsSocialMyfxbookUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/myfxbook"])},
      "toolsSocialFOLLOWME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOLLOWME"])},
      "toolsSocialFOLLOWMEUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/followme"])},
      "toolsAnalysisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis"])},
      "toolsAnalysisTradingCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Central"])},
      "toolsAnalysisTradingCentralUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market-analysis/trading-central"])},
      "toolsAnalysisEconomicCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic Calendar"])},
      "toolsAnalysisEconomicCalendarUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market-analysis/economic-calendar"])},
      "toolsTradingToolsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Tools"])},
      "toolsTradingToolsVPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VPS"])},
      "toolsTradingToolsVPSUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-tools/vps"])},
      "tradingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading"])},
      "tradingTransactionOverviewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction overview"])},
      "tradingTransactionOverviewPricingModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Model"])},
      "tradingTransactionOverviewPricingModelUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/pricing-model"])},
      "tradingTransactionOverviewTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
      "tradingTransactionOverviewTechnologyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/technology"])},
      "tradingTransactionOverviewAccountsComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts Comparison"])},
      "tradingTransactionOverviewAccountsComparisonUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/account"])},
      "tradingTransactionOverviewFlexibleFunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Funding"])},
      "tradingTransactionOverviewFlexibleFundingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/funding"])},
      "tradingConditionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Conditions"])},
      "tradingConditionContractSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Specifications"])},
      "tradingConditionContractSpecificationsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/contract-specification"])},
      "tradingConditionTradingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Calendar"])},
      "tradingConditionTradingCalendarUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/trading-calendar"])},
      "tradingConditionLeverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage"])},
      "tradingConditionLeverageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/leverage"])},
      "tradingConditionOvernightInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnight Interest"])},
      "tradingConditionOvernightInterestUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/overnight-interest"])},
      "tradingConditionDividend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividend"])},
      "tradingConditionDividendUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/dividend"])},
      "partnershipTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership"])},
      "partnershipPromotionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
      "partnershipPromotionPointsMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points Mall"])},
      "partnershipPromotionPointsMallUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://dooprimerewards.com/mall"])},
      "partnershipPromotionDooTraderTradingCompetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DooTrader Trading Competition"])},
      "partnershipPromotionDooTraderTradingCompetitionUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dootrader.com/en"])},
      "partnershipPartnersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "partnershipPartnersIntroducingBrokers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducing Brokers"])},
      "partnershipPartnersIntroducingBrokersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://dp.dooprimeio.com/en/partner/cooperation"])},
      "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
      "aboutOurStory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Story"])},
      "aboutOurStoryUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/aboutus"])},
      "aboutCorporateSocialResponsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Social Responsibility"])},
      "aboutCorporateSocialResponsibilityUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/?#"])},
      "aboutMediaCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Center"])},
      "aboutMediaCenterUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimenews.com/"])},
      "aboutCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
      "aboutComplianceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/compliance"])},
      "aboutContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "aboutContactUsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/contactus"])},
      "aboutCareers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
      "aboutCareersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/careers"])},
      "aboutHelpCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Centre"])},
      "aboutHelpCentreUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimehelp.com/en/"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read the following Compliance Disclosure carefully"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited."])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website can be accessed globally and is not specific to any entity. This is mainly for the convenience of centralized display and comparison of information. Your actual rights and obligations will be determined based on the entity and jurisdiction that you choose to be regulated."])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be of at least 18 years old before you can access our website products and services. By accessing our website, you confirm you are an individual of full age and capacity."])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There may be local laws and regulations which prohibit or limit your rights to access, download, distribute, disseminate, share or otherwise use any or all of the documents and information published on this website."])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High Risk Trading Products Risk Disclosure"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securities, Futures, CFDs and other financial products involve high risks due to the fluctuation in the value and prices of the underlying financial instruments. Due to the adverse and unpredictable market movements, large losses exceeding your initial investment could incur within a short period of time."])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past performance of an investment is not an indication of its performance in the future. Please read the compliance documents available on our website."])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure you fully understand the risks of trading with the respective financial instrument before engaging in any transactions with us. You should seek independent professional advice if you do not understand the risks explained herein."])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand and Entity Notice"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting and Doo Exchange are trading names, trademarks and intellectual properties of Doo Group."])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a variety of regulated and licensed entities in different jurisdictions to provide relevant financial services. You can choose to establish business relationships with any of our entities. Please bear in mind that the entity chosen meant that your trading accounts, trading activities and funds shall be governed and regulated by its respective regulatory authority only."])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not offer our services to individuals or companies of certain jurisdictions, including but not limited to Afghanistan, Azerbaijan, Bosnia and Herzegovina, Burundi, Cameroon, Canada, Central African Republic, Chad, Congo (Brazzaville), Congo (Kinshasa), Cuba, Equatorial Guinea, Eritrea, Ethiopia, Guinea-Bissau, Guyana, Haiti, Hong Kong, Iran , Iraq, Israel, Lebanon, Libya, Malaysia, Mali, Nicaragua, North Korea, Singapore, Somalia, South Sudan, Sri Lanka, Sudan, Syria, Tajikistan, Trinidad and Tobago, Tunisia, Turkmenistan, United Arab Emirates, United States, Venezuela, Yemen, Zimbabwe. The information and services on our website are not applicable and shall not be provided to country or jurisdiction where such distribution of information and services are contrary to the respective local statutes and regulations. Visitors from the above regions should confirm whether your decision to invest in our services is in accordance with the statutes and regulations in your country or jurisdiction before you use our services. We reserve our rights to vary, amend or discontinue our products and services at any time."])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Notice"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any trading symbols displayed herein are for illustrative purposes only and shall not constitute any advice or recommendation by us. Any comments, statements, data, information, material or third party material (“Material”) provided on this website are for reference purposes only. The Material is used solely for the purposes of marketing communication and does not contain, and shall not be construed as investment advice and/or an investment recommendation for any transactions. While we took all reasonable efforts to ensure the accuracy and completeness of the information, we make no representations and warranties to the Material and shall not be liable for any loss, including but not limited to loss of profit, direct or indirect loss or damages for any inaccuracies and incompleteness from the information provided. You shall only use the Material for personal use and shall not reproduce, copy, redistribute and/or license the Material without our consent."])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies on our websites to customize the information and experience displayed on our website according to your preferences.  By accessing this website, you acknowledge that you have read and agreed to the details above and agreed to our use of cookies."])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We strictly comply with all applicable laws and regulations in jurisdictions. It is your responsibility to determine and ensure that your investment meets your requirements. You undertake to bear all the consequences of your investment and trading activities."])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Entities Notice"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a limited company with a registered office at 7700 Windrose Avenue, STE 300 Plano, Texas 75024, United States."])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a broker-dealer registered with the US Securities and Exchange Commission (US SEC) and FINRA with the regulation number CRD: 24409 / SEC: 8-41551."])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PETER ELISH INVESTMENTS SECURITIES is an authorised trading name by Elish & Elish Inc."])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a limited company with the registered office at 208 Uxbridge Road, Shepherds Bush, London, England, W12 7JD, and the company registration number is 10684079."])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited is a futures and forex broker and liquidity provider, authorized and regulated by the Financial Conduct Authority (FCA), and the regulatory number is 833414."])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a company with the registered office at Level 4, 28-34 O'Connell Street, Sydney NSW 2000, and the Australian Company Number is 100 139 820."])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited is an Australian financial services licensee, regulated by the Australian Securities & Investments Commission, and the regulatory number is 222650."])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a limited company with the registered office at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2601567."])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited is a licensed trust or company service provider, authorized by Company Registry in Hong Kong, and the license number is TC006709."])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited It is a limited company with the registered office at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2169884."])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited is a licensed money service operator, permitted to conduct money exchange and remittance business, authorized by Customs and Excise Department, HKSAR and license number is 18-06-02444."])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a limited company with a registered office at Lot 15 A3, 1st Floor, Cybercity, Ebene 72201, Mauritius and the business registration number is C119023907."])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited is a licensed investment dealer, authorized and regulated by the Mauritius Financial Services Commission(FSC), and the regulatory number is C119023907."])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a limited liability company with its registered office at Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles with company registration number 8427202-1."])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited is a licensed securities dealer, and is authorized and regulated by the Seychelles Financial Services Authority (FSA) under regulatory number SD090."])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Agreement"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML & CTF Policy"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution Policy"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Policy"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Acknowledgement"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website T&C"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is owned and operated by Doo Prime Vanuatu Limited (Company No. 700238). © 2022 Doo Prime. All Rights Reserved."])}
  }
}