import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import Antd from 'ant-design-vue';
import axios from "axios"
import VueAxios from 'vue-axios';
import 'ant-design-vue/dist/antd.css';
import { plugin, defaultConfig} from '@formkit/vue'

createApp(App)
.use(i18n)
.use(plugin, defaultConfig)
.use(router)
.use(VueAxios, axios)
.use(Antd)
.mount('#app')
