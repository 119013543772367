<template>
    <section>
        <div class="register-section-web">
            <div class="register-left">
                <img src="@/assets/images/register-phone.png" />
            </div>
            <div class="register-right">
                <div class="register-container">
                    <h1 class="right-title">新一代专业交易平台</h1>
                    <img src="@/assets/images/Logo_InTrade.png" />
                    <p class="right-desc">享受超低点差极速交易</p>

                    <FormKit id="myForm" class="form" type="form" @submit="handleSubmit" :submit-attrs="{
                        inputClass: 'btn red submit'
                    }" incomplete-message="抱歉，并非所有字段都填写正确" submit-label="立即加入"> 


                        <FormKit type="email" name="Email" placeholder="邮箱" validation="required" :validation-messages="{required: '邮箱是必填项'}"/>

                    </FormKit>

                    <!-- <input class="right-input" :type="email" value="邮箱" />
                    <button class="right-submit" :on-click="submitInput()">立即加入</button> -->
                </div>
            </div>
            <div v-if="this.openDiv" class="form-popup-outer">
                <div class="form-popup">
                    <a href="https://user.dooprimeadw1.com/signup/index">
                        <button class="close-button"></button>
                    </a>
                    <div>
                        <img src="@/assets/images/icon-check.png" />
                        <h1>
                            感谢您的注册!
                        </h1>
                        <p>
                            即刻完善您的信息
                        </p>
                        <a class="remove-style" href="https://user.dooprimeadw1.com/signup/index ">
                            <div class="redirect-button">
                                前往平台
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="register-section-mobile">
            <img style="width:359px; height:305px;" src="@/assets/images/register-phone.png" />
            <div class="register-container">
                <h1 class="right-title">新一代专业交易平台</h1>
                <img src="@/assets/images/Logo_InTrade.png" />
                <p class="right-desc">享受超低点差极速交易</p>
                <FormKit id="myForm" class="form" type="form" @submit="handleSubmit" :submit-attrs="{
                        inputClass: 'btn red submit'
                    }" incomplete-message="抱歉，并非所有字段都填写正确" submit-label="立即加入"> 


                        <FormKit type="email" name="Email" placeholder="邮箱" validation="required" :validation-messages="{required: '邮箱是必填项'}"/>

                    </FormKit>

                <!-- <input class="right-input" :type="text" value="邮箱" />
                <button class="right-submit">立即加入</button> -->

            </div>
            <div v-if="this.openDiv" class="form-popup-outer">
                <div class="form-popup">
                    <a href="https://user.dooprimeadw1.com/signup/index">
                        <button class="close-button"></button>
                    </a>
                    <div>
                        <img src="@/assets/images/icon-check.png" />
                        <h1>
                            感谢您的注册!
                        </h1>
                        <p>
                            即刻完善您的信息
                        </p>
                        <a class="remove-style" href="https://user.dooprimeadw1.com/signup/index ">
                            <div class="redirect-button">
                                前往平台
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    data() {
        return {
            email: "",
            date: null,
            userEmail: "",
            openDiv: false
        }
    },
    methods: {
        handleSubmit(formValues) {
            this.checkDate()
            this.postData(formValues)
            console.log(this.date)
            console.log(formValues)

        },
        async postData(formValues) {
            const payload = {
                method: "POST",
                url: "/api/v1/client",
                data: {
                    email: formValues.Email,
                    date: this.date
                }
            }
            console.log(payload, 'hello')
            try {
                this.formSubmit = (true)
                let res = await this.axios(payload)
                console.log(res, "response")
                if (this.formSubmit == true) {
                    console.log("success")
                    this.openDiv = true
                    console.log(this.openDiv, "open div")
                } else {
                    console.log("failed")
                }
            } catch (error) {
                console.log(error)
            }
        },
        checkDate() {
            let current = new Date();
            let dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            // console.log(dateNow)
            this.date = dateNow
        },
        closePopup() {
            this.openDiv = false
            console.log(this.openDiv, "test")
        }
    },
    mounted() {
        this.checkDate();
    }
}
</script>

<style lang="scss">
    .formkit-inner {
        height:56px;
    }
.register-section-web {
    height: 790px;
    margin-top: 160px;
    display: flex;

    .form-popup-outer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;

        .form-popup {
            width: 456px;
            height: 452px;
            margin: auto;
            background-color: #fff;
            z-index: 11;
            position: sticky;
            z-index: 11;
            margin-top: 210px;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
                margin-top: 64px;
            }

            h1 {
                font-family: MicrosoftYaHei;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000018;
                padding-top: 21px;

            }

            p {
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                text-align: center;
                color: var(--charcoal-grey);
            }

            .redirect-button {
                width: 150px;
                height: 48px;
                margin: 48px 56px 0 49px;
                padding: 12px 16px;
                border-radius: 4px;
                background-color: #d91d22;
            }

            .remove-style {
                text-decoration: none;
                color: #fff;
            }
        }

        .close-button {
            width: 24px;
            height: 24px;
            background-image: url('@/assets/images/icon-close.png');
            background-size: cover;
            border: none;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .register-left {
        width: 60%;
    }

    .register-right {
        width: 40%;
        padding-top:70px;
        padding-bottom:93px;

        .register-container {
            width: 100%;
            max-width: 520px;
            height: 532px;
            box-shadow: 4px 4px 15px 0 rgba(227, 227, 227, 0.5);
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;

            .right-title {
                font-family: MicrosoftYaHei;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000018;
                margin-bottom: 16px;
                margin-top: 80px;
            }

            .right-desc {
                font-family: MicrosoftYaHei;
                font-size: 32px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 2px;
                text-align: center;
                color: #000018;
                margin: 48px 0;
            }

            .right-input {
                border: solid 1px #dbdbdb;
                background-color: #fff;
                width: 360px;
                max-width: 360px;
                height: 56px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #a2a2a2;
                padding-left: 16px;
            }

            .right-submit {
                background-color: #d91d22;
                max-width: 360px;
                width: 360px;
                margin-top: 32px;
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                text-align: center;
                color: #fefefe;
                border: none;
                height: 56px;
            }

            img {
                width: 203px;
                height: 48px;
            }
        }

    }

    //formkit styling
    [data-type="submit"] .formkit-input {
        background-color: #d91d22;
        width: 360px;
        height: 56px;
        border: none;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        margin-top:14px;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fefefe;
    }

    [data-type="email"] .formkit-input {
        width: 360px;
        height: 56px;
        margin-bottom: 32px;
        padding-left: 16px;
        border: solid 1px #dbdbdb;
        background-color: #fff;

    }
}

@media only screen and (min-width:377px) {
    .register-section-mobile {
        display: none;

    }
}

@include mobile-screen() {
    .register-section-web {
        display: none;
    }

    .register-section-mobile {
        display: block;
        margin-top: 80px;
        position: relative;
        margin-bottom:80px;
    }

    .form-popup-outer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;

        .form-popup {
            width: 343px;
            height: 393px;
            margin: auto;
            background-color: #fff;
            z-index: 11;
            position: sticky;
            z-index: 11;
            margin-top: 210px;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
                margin-top: 48px;
            }

            h1 {
                font-family: MicrosoftYaHei;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000018;
                padding-top: 21px;

            }

            p {
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                text-align: center;
                color: var(--charcoal-grey);
            }

            .redirect-button {
                width: 150px;
                height: 48px;
                margin: 48px 56px 0 49px;
                padding: 12px 16px;
                border-radius: 4px;
                background-color: #d91d22;
            }

            .remove-style {
                text-decoration: none;
                color: #fff;
            }
        }

        .close-button {
            width: 24px;
            height: 24px;
            background-image: url('@/assets/images/icon-close.png');
            background-size: cover;
            border: none;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .register-container {
        width: 100%;
        max-width: 343px;
        box-shadow: 4px 4px 15px 0 rgba(227, 227, 227, 0.5);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0px 16px 40px 16px;
        margin-bottom: 80px;
        margin: auto;

        .right-title {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 24px;
            letter-spacing: 1.5px;
            text-align: center;
            color: #000018;
            margin-bottom: 16px;
            margin-top: 40px;
        }

        .right-desc {
            font-family: MicrosoftYaHei;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: #000018;
            margin-top: 40px;
            margin-bottom: 24px;
        }

        .right-input {
            border: solid 1px #dbdbdb;
            background-color: #fff;
            width: 311px;
            max-width: 311px;
            height: 56px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #a2a2a2;
            padding-left: 16px;
        }

        .right-submit {
            background-color: #d91d22;
            max-width: 311px;
            width: 311px;
            margin-top: 32px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            margin-top:10px;
            letter-spacing: 1.5px;
            text-align: center;
            color: #fefefe;
            border: none;
            height: 56px;
        }

        img {
            width: 177px;
            height: 42px;
        }
    }

    //formkit styling
    [data-type="submit"] .formkit-input {
        background-color: #d91d22;
        width: 311px;
        height: 56px;
        border: none;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        margin-top:24px;
        text-align: center;
        color: #fefefe;
    }

    [data-type="email"] .formkit-input {
        width: 311px;
        height: 56px;
        margin-bottom: 24px;
        padding-left: 16px;
        border: solid 1px #dbdbdb;
        background-color: #fff;

    }
}

.formkit-messages{
    font-size:12px;
    color:red;
    margin-left:14px;
    text-align:left;
}
</style>