<template>
    <section class="steps-section">
        <div class="steps-section-web">
            <h1 class="steps-header">简单四步 轻松开户</h1>
            <div class="steps-outer">
                <div class="column">
                    <div class="circle">01</div>
                    <h1>注册</h1>
                    <p>一键点击，“立即开户”</p>
                </div>
                <div class="column">
                    <div class="divider">

                    </div>
                </div>
                <div class="column">
                    <div class="circle">02</div>
                    <h1>验证</h1>
                    <p>验证信息，激活账户</p>
                </div>
                <div class="column">
                    <div class="divider">

                    </div>
                </div>
                <div class="column">
                    <div class="circle">03</div>
                    <h1>入金</h1>
                    <p>快速入金，安全方便</p>
                </div>
                <div class="column">
                    <div class="divider">

                    </div>
                </div>
                <div class="column">
                    <div class="circle">04</div>
                    <h1>交易</h1>
                    <p>开始交易，投资全球</p>
                </div>
            </div>
        </div>
        <div class="steps-section-mobile">
            <h1 class="section-title">简单四步 轻松开户</h1>
            <div class="container-outer">
                <div class="container-inner">
                    <div class="row">
                        <div class="row-inner">
                            <div class="circle">01</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <p class="steps-title">注册</p>
                            <p class="steps-desc">一键点击，“立即开户”</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-inner">
                            <div class="circle">02</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <p class="steps-title">验证</p>
                            <p class="steps-desc">验证信息，激活账户</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-inner">
                            <div class="circle">03</div>
                            <div class="line"></div>
                        </div>
                        <div>
                            <p class="steps-title">入金</p>
                            <p class="steps-desc">快速入金，安全方便</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-inner">
                            <div class="circle">04</div>
                        </div>
                        <div>
                            <p class="steps-title">交易</p>
                            <p class="steps-desc">开始交易，投资全球</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'StepsSection'
}
</script>

<style lang="scss">
.steps-title {
    font-family:MicrosoftYaHei;
    font-size: 28px;
}

.steps-section {
    // height: 605px;
    background-color: #fbfbfd;

    .steps-section-web {
        margin-top: 160px;
        padding-bottom: 120px;

        .steps-header {
            padding-top: 100px;
            font-family: MicrosoftYaHei;
            font-size: 48px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
        }

        .steps-outer {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            margin-top: 100px;
            padding-left: 120px;
            padding-right: 120px;

            .column {
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;

                h1 {
                    font-family: MicrosoftYaHei;
                    font-size: 28px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1.5px;
                    color: #000018;
                }

                p {
                    font-family: MicrosoftYaHei;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: 1.5px;
                    text-align: center;
                    color: var(--charcoal-grey);
                }
            }

            .circle {
                width: 64px;
                height: 64px;
                border-radius: 50%;
                background-color: #d91d22;
                margin-bottom: 64px;
                color: #fff;
                font-size: 28px;
                font-weight: bold;
                padding-top: 10px;
                padding-left: 0px
            }

            .divider {
                border-bottom: 1px solid #e4e4e4;
                display: block;
                width: 190px;
                margin-top: 16%;
            }
        }
    }
}

@media only screen and (min-width:377px) {
    .steps-section-mobile {
        display: none;
    }
}

@include mobile-screen() {
    .steps-section-web {
        display: none;
    }

    .steps-section-mobile {
        display: block;

        .section-title {
            font-family: MicrosoftYaHei;
            padding-top: 40px;
            font-size: 28px;
            letter-spacing: 1.5px;
        }

        .container-outer {
            display: flex;
            flex-direction: column;
            // align-items: baseline;
            margin-left: 48px;
            padding-top: 40px;

        }

        .row {
            display: flex;
            flex-direction: row;
            padding-bottom: 30px;
            position: relative;
            // align-items: baseline;

            .row-inner {
                margin-right: 16px;
            }


            div {
                text-align: left;
                // padding-left: 16px;
            }

            .steps-title {
                padding-bottom: 24px;
                font-size: 20px;
                margin-bottom: 0;
                // position: relative;
            }

            .steps-desc {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 1.5px;
            }
        }

        .circle {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: #d91d22;
            margin-bottom: 64px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            padding-top: 5px;
            padding-left: 8px
        }

        .line {
            content: "";
            margin-top: 8px;
            position: absolute;
            top: 32px;
            bottom: 0;
            left: 16px;
            width: 1px;
            background-color: #e4e4e4;
            padding-left: 0px !important;
            height: 80px;
        }
    }
}
</style>