<template>
    <div class="footer-top-banner">
    </div>
    <div id="footer-top">
        <div class="footer-top-wrapper container">
            <div class="section-about">
                <img class="about-logo" data-aos="fade-up" data-aos-duration="1000" :src="imageUrl + 'footer-logo.png'"
                    alt="logo DooPrime" />
                <div class="contact-container phone">
                    <h4 class="contact-title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"
                        v-t="'footer.contact.phoneTitle'"></h4>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
                        v-t="'footer.contact.phoneEurope'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300"
                        v-t="'footer.contact.phoneAsia'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400"
                        v-t="'footer.contact.phoneSingapore'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500"
                        v-t="'footer.contact.phoneChina'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500"
                        v-t="'footer.contact.phoneVietnam'"></p>
                </div>
                <div class="contact-container email">
                    <h4 class="contact-title" data-aos="fade-up" data-aos-duration="1000"
                        v-t="'footer.contact.emailTitle'"></h4>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100"
                        v-t="'footer.contact.emailAccountManager'"></p>
                    <p class="contact-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"
                        v-t="'footer.contact.emailTechnicalSupport'"></p>
                </div>
                <div class="social-media">
                    <a href="https://www.facebook.com/DooPrime/" data-aos="fade-left" data-aos-duration="1000"
                        target="_blank"><img :src="imageUrl + 'facebook.svg'" alt="facebook" /></a>
                    <a href="https://www.instagram.com/dooprime/" data-aos="fade-left" data-aos-duration="1000"
                        data-aos-delay="100" target="_blank"><img :src="imageUrl + 'instagram.svg'"
                            alt="instagram" /></a>
                    <a href="https://www.linkedin.com/authwall?trk=bf&amp;trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&amp;originalReferer=&amp;sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited"
                        data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200" target="_blank"><img
                            :src="imageUrl + 'linkedin.svg'" alt="linkedin" /></a>
                    <a href="https://twitter.com/DooPrime" data-aos="fade-left" data-aos-duration="1000"
                        data-aos-delay="300" target="_blank"><img :src="imageUrl + 'twitter.svg'" alt="twitter" /></a>
                    <!-- <a href="https://mp.weixin.qq.com/s/XgK46goPzqy3cRHczrd1Ag" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="400" target="_blank"><img :src="imageUrl + 'wechat.svg'" alt="wechat"/></a> -->
                </div>
            </div>
            <div class="section-menu">
                <div :class="`section-menu-row${menuTabActive === key ? ' active' : ''}`"
                    v-for="(row, key) in footerMenu">
                    <h4 class="row-title" data-aos="fade-up" data-aos-duration="1000" v-t="`footer.menu.${row.title}`"
                        @click="setMenuActive(key)"></h4>
                    <div class="row-submenu-wrapper" ref="items">
                        <div v-if="row.items" class="row-submenu">
                            <div v-for="link in row.items" class="menu-link footer-content"
                                :href="$t(`footer.menu.${link[1]}`)" data-aos="fade-up" data-aos-duration="1000"
                                v-t="`footer.menu.${link[0]}`"></div>
                        </div>
                        <div v-if="row.group" class="row-submenu-1" v-for="submenu in row.group">
                            <h5 class="submenu-1-title" data-aos="fade-up" data-aos-duration="1000"
                                v-t="`footer.menu.${submenu.title}`"></h5>
                            <div v-if="submenu.items" class="row-submenu-2" data-aos="fade-up" data-aos-duration="1000">
                                <div v-for="link in submenu.items" class="menu-link footer-content"
                                    :href="$t(`footer.menu.${link[1]}`)" v-t="`footer.menu.${link[0]}`"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const footerMenu = [
    {
        title: "marketTitle",
        items: [
            ["marketSecurities", "marketSecuritiesUrl"],
            ["marketFutures", "marketFuturesUrl"],
            ["marketForex", "marketForexUrl"],
            ["marketMetals", "marketMetalsUrl"],
            ["marketCommodities", "marketCommoditiesUrl"],
            ["marketStock", "marketStockUrl"]
        ],
    },
    {
        title: "toolsTitle",
        group: [
            {
                title: "toolsPlatformTitle",
                items: [
                    ["toolsPlatformMetaTrader4", "toolsPlatformMetaTrader4Url"],
                    ["toolsPlatformMetaTrader5", "toolsPlatformMetaTrader5Url"],
                    ["toolsPlatformMT4vsMT5", "toolsPlatformMT4vsMT5Url"],
                    ["toolsPlatformDooPrimeInTrade", "toolsPlatformDooPrimeInTradeUrl"],
                    ["toolsPlatformTradingView", "toolsPlatformTradingViewUrl"],
                    ["toolsPlatformFIXAPI", "toolsPlatformFIXAPIUrl"],
                ]
            },
            {
                title: "toolsSocialTitle",
                items: [
                    ["toolsSocialOutrade", "toolsSocialOutradeUrl"],
                    ["toolsSocialMyfxbook", "toolsSocialMyfxbookUrl"],
                    ["toolsSocialFOLLOWME", "toolsSocialFOLLOWMEUrl"],
                ]
            },
            {
                title: "toolsAnalysisTitle",
                items: [
                    ["toolsAnalysisTradingCentral", "toolsAnalysisTradingCentralUrl"],
                    ["toolsAnalysisEconomicCalendar", "toolsAnalysisEconomicCalendarUrl"],
                ]
            },
            {
                title: "toolsTradingToolsTitle",
                items: [
                    ["toolsTradingToolsVPS", "toolsTradingToolsVPSUrl"],
                ]
            },
        ]
    },
    {
        title: "tradingTitle",
        group: [
            {
                title: "tradingTransactionOverviewTitle",
                items: [
                    ["tradingTransactionOverviewPricingModel", "tradingTransactionOverviewPricingModelUrl"],
                    ["tradingTransactionOverviewTechnology", "tradingTransactionOverviewTechnologyUrl"],
                    ["tradingTransactionOverviewAccountsComparison", "tradingTransactionOverviewAccountsComparisonUrl"],
                    ["tradingTransactionOverviewFlexibleFunding", "tradingTransactionOverviewFlexibleFundingUrl"],
                ]
            },
            {
                title: "tradingConditionsTitle",
                items: [
                    ["tradingConditionContractSpecifications", "tradingConditionContractSpecificationsUrl"],
                    ["tradingConditionTradingCalendar", "tradingConditionTradingCalendarUrl"],
                    ["tradingConditionLeverage", "tradingConditionLeverageUrl"],
                    ["tradingConditionOvernightInterest", "tradingConditionOvernightInterestUrl"],
                    ["tradingConditionDividend", "tradingConditionDividendUrl"],
                ]
            },
        ]
    },
    {
        title: "partnershipTitle",
        group: [
            {
                title: "partnershipPromotionTitle",
                items: [
                    ["partnershipPromotionPointsMall", "partnershipPromotionPointsMallUrl"],
                    ["partnershipPromotionDooTraderTradingCompetition", "partnershipPromotionDooTraderTradingCompetitionUrl"],
                ]
            },
            {
                title: "partnershipPartnersTitle",
                items: [
                    ["partnershipPartnersIntroducingBrokers", "partnershipPartnersIntroducingBrokersUrl"]
                ]
            },
        ]
    },
    {
        title: "aboutTitle",
        items: [
            ["aboutOurStory", "aboutOurStoryUrl"],
            ["aboutCorporateSocialResponsibility", "aboutCorporateSocialResponsibilityUrl"],
            ["aboutMediaCenter", "aboutMediaCenterUrl"],
            ["aboutCompliance", "aboutComplianceUrl"],
            ["aboutContactUs", "aboutContactUsUrl"],
            ["aboutCareers", "aboutCareersUrl"],
            ["aboutHelpCentre", "aboutHelpCentreUrl"],
        ],
    },
]

export default {
    name: "FooterTop",
    methods: {
        setMenuActive(id) {
            if (this.menuTabActive === id) {
                this.menuTabActive = -1;
            } else {
                this.menuTabActive = id;
            }
        }
    },
    data() {
        return {
            footerMenu: footerMenu,
            menuTabActive: -1,
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/q3-event-agent/images/"
        }
    }
}
</script>

<style lang="scss">
@mixin mobile-footerTop() {
    @media screen and (max-width: 900px) {
        @content;
    }
}

#footer-top {
    position: relative;
    // padding: 81px 0 58px;
    padding-left: 24px;
    padding-top: 80px;
    background-color: #f7f8f9;
    padding-bottom: 80px;

    @media screen and (min-width:1025px) {
        padding-left: 160px;
        padding-right: 160px;
    }

    @include mobile-footerTop() {
        padding: 34px 0 25px;
        padding-left: 26px;
        padding-right: 24px;
    }


    .footer-top-wrapper {
        display: flex;

        @include mobile-footerTop() {
            flex-direction: column;
        }

        .section-about {
            width: 100%;
            max-width: 35%;
            position: relative;

            @include mobile-footerTop() {
                max-width: initial;
            }

            .about-logo {
                margin-bottom: 87px;

                @include mobile-footerTop() {
                    margin-bottom: 43px;
                    width: 171px;
                }
            }

            .contact-container {
                margin-bottom: 56px;

                @include mobile-footerTop() {
                    margin-bottom: 52px;
                }

                .contact-title {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: 1.71px;
                    color: #000018;
                    margin-bottom: 16px;
                    margin-top: 0;

                    @include mobile-footerTop() {
                        margin-top: 0;
                        margin-bottom: 19px;
                    }
                }

                .contact-item {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: 1.5px;
                    color: #36383b;
                    margin-bottom: 16px;

                    @include mobile-footerTop() {
                        margin-top: 0;
                        margin-bottom: 19px;
                    }
                }
            }

            .social-media {
                position: absolute;
                left: 0;
                bottom: 0;

                @include mobile-footerTop() {
                    position: relative;
                    margin-bottom: 43px;
                }

                a {
                    display: inline-block;
                    height: 25px;
                    margin-right: 24px;
                    margin-bottom: 0;
                }

            }
        }

        .section-menu {
            display: flex;
            width: 100%;

            @include mobile-footerTop() {
                flex-wrap: wrap;
            }

            .section-menu-row {
                overflow: hidden;
                width: 20%;

                @include mobile-footerTop() {
                    width: 100%;
                    border-top: 1px solid #e4e4e4;
                    padding: 17px 0;

                    &:last-child {
                        border-bottom: 1px solid #e4e4e4;
                    }
                }

                .row-title {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1.5px;
                    color: #36383b;
                    margin-bottom: 16px;
                    position: relative;
                    margin-top: 0;

                    @include mobile-footerTop() {
                        &::after {
                            content: url('@/assets/icon-common-plus.svg');
                            position: absolute;
                            right: 0;
                            top: 50%;
                            // padding-right:24px;
                            transform: translateY(-50%);
                        }
                    }
                }

                .row-submenu-1 {
                    &:nth-child(n+3) {
                        margin-top: 32px;
                    }

                    .submenu-1-title {
                        position: relative;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        letter-spacing: 1.5px;
                        color: #000000;
                        margin-bottom: 16px;
                        padding-left: 7px;

                        @include mobile-footerTop() {
                            margin: 0;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 2px;
                            top: 0;
                            left: 0;
                            background-color: #d91d22;
                        }
                    }
                }

                @include mobile-footerTop() {
                    .row-submenu-wrapper {
                        max-height: 0;
                        margin-top: 0;
                        display: none;
                    }

                    .row-title {
                        margin-bottom: 0;
                        margin-top: 0;
                    }

                    &.active {
                        .row-submenu-wrapper {
                            max-height: 100vh;
                            margin-top: 16px;
                            display: initial;
                        }

                        .row-title {
                            &::after {
                                content: url('@/assets/icon-common-minus.svg');
                            }
                        }
                    }
                }
            }
        }

        .footer-content {
            text-decoration: none;
            display: block;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
            margin-bottom: 16px;

            &:hover {
                color: #ec3f33;
            }

            @include mobile-footerTop() {
                &:first-child {
                    margin-top: 16px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.footer-top-banner {
    background-image: url('@/assets/images/footer-desktop-ball.png');
    background-position: 35%;
    width: 100%;
    height: 240px;
}

@include mobile-footerTop() {
    .footer-top-banner {
        background-image: url('@/assets/images/footer-mobile-ball.png');
        height: 385px;
        width: 100%;
        background-position: 50%;
    }
}
</style>