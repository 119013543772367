<template>
    <section>
        <div class="info-section-web">
            <div>
                <h1 class="info-title">
                    顶级交易环境
                </h1>
                <p class="info-desc">
                    全球领先、值得信赖的互联网券商，安享全球投资交易回报
                </p>
            </div>
            <div class="container">
                <div class="row">
                    <div class="box border-right border-bottom">
                        <img src="@/assets/images/shield.png" />
                        <h1 class="container-title">
                            多重严格监管
                        </h1>
                        <p class="container-desc">
                            Doo Prime 受塞舌尔 FSA、毛里求斯 FSC 以及瓦努阿图 FSC 等多国金融机构的严格监管。
                        </p>
                    </div>
                    <div class="box border-right border-bottom">
                        <img src="@/assets/images/spread.png" />
                        <h1 class="container-title">
                            超低点差
                        </h1>
                        <p class="container-desc">
                            点差低至 0.1 pips，力求将交易成本降至最低。
                        </p>
                    </div>
                    <div class="box border-bottom">
                        <img src="@/assets/images/apps.png" />
                        <h1 class="container-title">
                            逾 10,000 个交易品种
                        </h1>
                        <p class="container-desc">
                            全面接触超过 10,000 个产品的差价合约，捕捉无尽交易机会。
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="box border-right">
                        <img class="box-img" src="@/assets/images/service.png" />
                        <h1 class="container-title">
                            24*7*365 专业支援
                        </h1>
                        <p class="container-desc">
                            Doo Prime 拥有 500+ 行业精英，为客户提供全年 365 天无休止的顶级支援服务。
                        </p>
                    </div>
                    <div class="box border-right">
                        <img class="box-img" src="@/assets/images/trader.png" />
                        <h1 class="container-title">
                            极速交易
                        </h1>
                        <p class="container-desc">
                            数据中心部署于 Equinix，99.5% 的交易订单在 50 毫秒被执行。
                        </p>
                    </div>
                    <div class="box">
                        <img class="box-img" src="@/assets/images/balance.png" />
                        <h1 class="container-title">
                            1000 倍杠杆率
                        </h1>
                        <p class="container-desc">
                            以小额资金开始交易，并自由选用高至 1000 倍的杠杆率，将回报最大化。
                        </p>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
        <div class="info-section-mobile">
            <div>
                <h1 class="info-title">
                    顶级交易环境
                </h1>
                <p class="info-desc">
                    全球领先、值得信赖的互联网券商，安享全球投资交易回报
                </p>
            </div>
            <swiper :slides-per-view="1" :space-between="50" @sipwer="onSwiper" @slidechange="onSlideChange"
                :pagination="true" :modules="modules">
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/shield.png" />

                        <h2>
                            多重严格监管
                        </h2>
                        <p>
                            Doo Prime 受塞舌尔 FSA、毛里求斯 FSC 以及瓦努阿图 FSC 等多国金融机构的
                            严格监管。
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/spread.png" />

                        <h2>
                            超低点差
                        </h2>
                        <p>
                            点差低至 0.1 pips，力求将交易成本降至最低。
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/apps.png" />

                        <h2>
                            逾 10,000 个交易品种
                        </h2>
                        <p>
                            全面接触超过 10,000 个产品的差价合约，捕捉无尽交易机会。
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/service.png" />

                        <h2>
                            24*7*365 专业支援
                        </h2>
                        <p>
                            Doo Prime 拥有 500+ 行业精英，为客户提供全年 365 天无休止的顶级支援服务。
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/trader.png" />

                        <h2>
                            极速交易
                        </h2>
                        <p>
                            数据中心部署于 Equinix，99.5% 的交易订单在 50 毫秒被执行。
                        </p>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div>
                        <img class="mobile-swiper-image" src="@/assets/images/balance.png" />

                        <h2>
                            1000 倍杠杆率
                        </h2>
                        <p>
                            以小额资金开始交易，并自由选用高至 1000 倍的杠杆率，将回报最大化。
                        </p>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from "swiper";
import 'swiper/css'
import "swiper/css/pagination";

export default {
    name: "InfoSection",
    components: {
        Swiper,
        SwiperSlide,
        Pagination
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log('slide change')
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [Pagination],
        }
    }
}
</script>

<style lang="scss">
.info-section-web {
    max-width: 1920px;
    margin: auto;

    .info-title {
        font-family: MicrosoftYaHei;
        font-size: 48px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.5px;
        color: #000018;
        margin-top: 120px;
    }

    .info-desc {
        font-family: MicrosoftYaHei;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        color: var(--charcoal-grey);
        margin-top: 17px;
    }

    .container {
        display: flex;
        max-width: 1920px;
        flex-direction: column;
        margin-left: 160px;
        margin-right: 160px;
        margin-top: 79px;

        .container-title {
            font-family: MicrosoftYaHei;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000018;
            margin-top: 8px;
        }

        .container-desc {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            color: var(--charcoal-grey);
            margin-top: 20px;
            max-width: 397px;
            text-align: center;
            padding-right: 20px;
            padding-left: 20px;

        }

        img {
            width: 100%;
            height: 100%;
            max-width: 280px;
            max-height: 160px;
        }

        .row {
            display: flex;
            flex: 1;

            .box {
                height: 360px;
                width: 528px;
                display: flex;
                align-items: center;
                flex-direction: column;

                .box-img {
                    margin-top: 68px;
                }
            }

            .border-right {
                border-right: 1px solid #e4e4e4;
            }

            .border-bottom {
                border-bottom: 1px solid #e4e4e4;
            }
        }
    }
}

@media only screen and (min-width:377px) {
    .info-section-mobile {
        display: none;
    }
}

@include mobile-screen() {
    .info-section-web {
        display: none;
    }

    .swiper-pagination {
        position:relative;
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        background-color: #d91d22;
        width: 4px;
        height: 4px;
        border-radius: 0;
    }

    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 0;
    }

    .info-section-mobile {
        display: block;
        padding-top: 80px;
        margin-bottom: 80px;
        // height: 624px;

        .mobile-swiper-image {
            width: 280px;
            height: 160px;
            margin-top:37px;
        }

        h2 {
            font-family: MicrosoftYaHei;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: rgba(16, 16, 16, 0.95);
        }

        p {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            color: var(--charcoal-grey);
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;
            margin: auto;
            // height: 150px;
            padding-bottom:32px;
            max-width: 375px;
        }

        .info-title {
            font-family: MicrosoftYaHei;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
        }

        .info-desc {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            height:48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            color: var(--charcoal-grey);
        }
    }
}
</style>