<template>
  <BannerTop/>
  <InfoSection/>
  <StepsSection/>
  <RegisterSection/>
  <FooterComponent/>
  <!-- <FooterSection /> -->
  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <p>{{$t('message')}}</p>
  </div> -->
</template>

<script>

import BannerTop from '@/components/BannerTop.vue'
import InfoSection from '@/components/InfoSection.vue'
import StepsSection from '@/components/StepsSection.vue'
import RegisterSection from '@/components/RegisterSection.vue'
import FooterComponent from '@/components/Footer/Footer.vue'


export default {
  name: 'HomeView',
  components: {
    BannerTop,
    InfoSection,
    StepsSection,
    RegisterSection,
    FooterComponent
  }
}
</script>

<style>
</style>