<template>
    <section>
        <div class="banner-top">
            <img class="banner-logo" src="@/assets/images/logo_dp.png" />
            <div class="banner-right">
                <div>
                    <img src="@/assets/images/Logo_InTrade.png" />
                </div>
                <div>
                    <p class="banner-title">
                        选择新一代专业交易平台
                    </p>
                    <p class="banner-title lower">
                        平台月交易量达 <span class="red-text">500 亿美元</span>
                    </p>
                </div>
                <div class="text-box-outer">
                    <p class="banner-desc">
                        享受超低点差极速交易
                    </p>
                    <FormKit id="myForm" class="form" type="form" @submit="handleSubmit" :submit-attrs="{
                        inputClass: 'btn red submit'
                    }" incomplete-message="抱歉，并非所有字段都填写正确" submit-label="立即加入"> 


                        <FormKit type="email" name="Email" placeholder="邮箱" validation="required" :validation-messages="{required: '邮箱是必填项'}"/>

                    </FormKit>
                    <!-- <input class="text-box" :value="text" :placeholder="'text'" />
                    <input class="banner-submit" type="button" /> -->
                </div>
            </div>
            <div v-if="this.openDiv" class="form-popup-outer">
                <div class="form-popup">
                    <a href="https://user.dooprimeadw1.com/signup/index">
                        <button class="close-button"></button>
                    </a>
                    <div>
                        <img src="@/assets/images/icon-check.png" />
                        <h1>
                            感谢您的注册!
                        </h1>
                        <p>
                            即刻完善您的信息
                        </p>
                        <a class="remove-style" href="https://user.dooprimeadw1.com/signup/index ">
                            <div class="redirect-button">
                                前往平台
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            date: null,
            userEmail: "",
            openDiv: false
        }
    },
    methods: {
        handleSubmit(formValues) {
            this.checkDate()
            this.postData(formValues)
            console.log(this.date)
            console.log(formValues)

        },
        async postData(formValues) {
            const payload = {
                method: "POST",
                url: "/api/v1/client",
                data: {
                    email: formValues.Email,
                    date: this.date
                }
            }
            console.log(payload, 'hello')
            try {
                this.formSubmit = (true)
                let res = await this.axios(payload)
                console.log(res, "response")
                if (this.formSubmit == true) {
                    console.log("success")
                    this.openDiv = true
                    console.log(this.openDiv, "open div")
                } else {
                    console.log("failed")
                }
            } catch (error) {
                console.log(error)
            }
        },
        checkDate() {
            let current = new Date();
            let dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            // console.log(dateNow)
            this.date = dateNow
        },
        closePopup() {
            this.openDiv = false
            console.log(this.openDiv, "test")
        }
    },
    mounted() {
        this.checkDate();
    }
}
</script>

<style lang="scss">
.banner-top {
    background-image: url(@/assets/images/Header_Desktop2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40%;
    // background-position-y:-150px;
    width: 100%;
    height: 850px;
    // max-width: 1920px;
    // display:flex;    
    position: relative;

    .banner-logo {
        position:absolute;
        left:172px;
        top:24px;
        height:38px;
        width:160px; 
    }

    .banner-right {
        // width: 100%;
        max-width: 620px;
        margin-left: 60%;
        padding-top: 186px;
        display: flex;
        justify-content: left;
        align-items: flex-start;
        flex-direction: column;

        .form-popup-outer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;

        .form-popup {
            width: 343px;
            height: 393px;
            margin: auto;
            background-color: #fff;
            z-index: 11;
            position: sticky;
            z-index: 11;
            margin-top: 210px;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
                margin-top: 48px;
            }

            h1 {
                font-family: MicrosoftYaHei;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000018;
                padding-top: 21px;

            }

            p {
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                text-align: center;
                color: var(--charcoal-grey);
            }

            .redirect-button {
                width: 150px;
                height: 48px;
                margin: 48px 56px 0 49px;
                padding: 12px 16px;
                border-radius: 4px;
                background-color: #d91d22;
            }

            .remove-style {
                text-decoration: none;
                color: #fff;
            }
        }

        .close-button {
            width: 24px;
            height: 24px;
            background-image: url('@/assets/images/icon-close.png');
            background-size: cover;
            border: none;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

        img {
            width: 100%;
            height: 100%;
            max-width: 257px;
            max-height: 61px;
            margin-bottom: 16px;

        }

        p {
            text-align: left;
            max-width: 620px;
            // width: 100%;
        }

        .banner-title {
            font-family: MicrosoftYaHei;
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
            margin-top: 16px;
            margin-bottom: 0px;
        }

        .red-text {
            font-weight: bold;
            color: #d91d22;
        }

        .banner-desc {
            margin-top: 64px;
            font-family: MicrosoftYaHei;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
        }

        .text-box-outer {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .text-box {
                width: 100%;
                max-width: 517px;
                height: 56px;
            }

            .banner-submit {
                margin-top: 24px;
                width: 100%;
                max-width: 517px;
                height: 56px;
                background-color: #d91d22;
                border: none;
            }
        }

        //formkit styling

        .formkit-form {
            width: 100%;
            text-align: left;
        }

        [data-type="submit"] .formkit-input {
            background-color: #d91d22;
            width: 100%;
            max-width: 517px;
            height: 56px;
            border: none;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            margin-top:24px;
            color: #fefefe;
        }

        [data-type="email"] .formkit-input {
            width: 100%;
            max-width: 517px;
            height: 56px;
            margin-bottom: 24px;
            padding-left: 16px;
            border: solid 1px #dbdbdb;
            background-color: #fff;

        }
    }
    .form-popup-outer {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;

        .form-popup {
            width: 343px;
            height: 393px;
            margin: auto;
            background-color: #fff;
            z-index: 11;
            position: sticky;
            z-index: 11;
            margin-top: 210px;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
                margin-top: 48px;
            }

            h1 {
                font-family: MicrosoftYaHei;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.5px;
                text-align: center;
                color: #000018;
                padding-top: 21px;

            }

            p {
                font-family: MicrosoftYaHei;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.5px;
                text-align: center;
                color: var(--charcoal-grey);
            }

            .redirect-button {
                width: 150px;
                height: 48px;
                margin: 48px 56px 0 49px;
                padding: 12px 16px;
                border-radius: 4px;
                background-color: #d91d22;
            }

            .remove-style {
                text-decoration: none;
                color: #fff;
            }
        }

        .close-button {
            width: 24px;
            height: 24px;
            background-image: url('@/assets/images/icon-close.png');
            background-size: cover;
            border: none;
            background-color: #fff;
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

}

@include mobile-screen() {
    .banner-top {
        background-image: url('@/assets/images/Header_Mobile2.png');
        background-position: 47%;
        height: 950px;

        .banner-logo {
        position:absolute;
        left:16px;
        top:16px;
        height:38px;
        width:160px; 
    }

        //formkit styling
        [data-type="submit"] .formkit-input {
            background-color: #d91d22;
            width: 311px;
            height: 56px;
            border: none;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: normal;
            margin-top:24px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.5px;
            text-align: center;
            color: #fefefe;
        }

        [data-type="email"] .formkit-input {
            width: 311px;
            height: 56px;
            margin-bottom: 24px;
            padding-left: 16px;
            border: solid 1px #dbdbdb;
            background-color: #fff;

        }

        .banner-right {
            margin: auto;
            padding-top: 99px;
            margin-bottom:40px;
            width: 325px;
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;

            p {
                text-align: center;
            }

            img {
                width: 177px;
                height: 42px;
            }

            .lower {
                margin: auto;
                width: 207px !important;
            }

            .banner-title {
                font-size: 28px;
                width:325px;
                line-height:42px;

            }

            .text-box-outer {
                padding-top: 428px;
                max-width: 311px;
                margin: auto;
                width: 100%;

                .banner-desc {
                    font-size: 20px;
                    margin: auto;
                    margin-bottom: 24px;
                }

                .text-box {
                    margin-top: 24px;
                }
            }
        }

    }

}

.formkit-messages{
    font-size:12px;
    color:red;
    height:5px;
    margin-left:14px;
    text-align:left;
}
</style>