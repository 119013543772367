export default {
  "banner": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i18n !!"])}
  },
  "footer": {
    "number_title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话："])},
    "contact_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199 (欧洲)"])},
    "contact_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241 (亚洲)"])},
    "contact_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (亚洲— 新加坡)"])},
    "contact_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539 (亚洲—中国地区)"])},
    "contact_title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱："])},
    "contact_email1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.sales", "@", "dooprime.com（客户经理）"])},
    "contact_email2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.support", "@", "dooprime.com（技术支持） "])},
    "market_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场"])},
    "market_securities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证券"])},
    "market_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期货"])},
    "market_forex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币对"])},
    "market_precious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贵金属"])},
    "market_commodities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗商品"])},
    "market_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股票指数"])},
    "tools_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工具"])},
    "tools_trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易软件"])},
    "tools_meta4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTrader 4"])},
    "tools_meta5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetraTrader 5"])},
    "tools_4v5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tools_intrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime InTrade"])},
    "tools_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TradingView"])},
    "tools_fix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIX API"])},
    "tools_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区化交易"])},
    "tools_outrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outrade"])},
    "tools_myfxbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myfxbook"])},
    "tools_followme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOLLOWME"])},
    "tools_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深度分析"])},
    "tools_central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Central "])},
    "tools_economic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财经日历"])},
    "tools_trading_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "tools_vps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VPS 交易专用服务器"])},
    "section_risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高风险交易产品风险披露"])},
    "section_risk_desc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于基础金融工具的价值和价格会有剧烈变动，股票，证券，期货，差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。"])},
    "section_risk_desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立专业的意见。"])},
    "section_risk_desc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去的投资表现并不代表其未来的表现。"])},
    "section_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌和实体公告"])},
    "section_brand_desc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting 和Doo Exchange 是Doo Group 所拥有的商用名称、注册商标和知识产权。"])},
    "section_brand_desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有各种受监管和许可的实体提供相关的金融服务。 您可以与我们的任何实体建立业务关系。 所选择的实体意味着您的交易账户，交易活动和资金只由这特定的监管机构进行监管。"])},
    "section_brand_desc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们不向某些司法管辖区的个人和公司提供服务，包括但不限于阿富汗、阿塞拜疆、波斯尼亚和黑塞哥维那、布隆迪、喀麦隆、加拿大、中非共和国、乍得、刚果（布拉柴维尔）、刚果（金沙萨）、古巴、赤道几内亚、厄立特里亚、埃塞俄比亚、几内亚比绍、圭亚那、海地、香港、伊朗、伊拉克、以色列、黎巴嫩、利比亚、马来西亚、马里、尼加拉瓜、朝鲜、新加坡、索马里、南苏丹、斯里兰卡、苏丹、叙利亚、塔吉克斯坦、特立尼达和多巴哥、突尼斯、土库曼斯坦、阿拉伯联合酋长国，美国，委内瑞拉，也门，津巴布韦。 我们网站上的信息和服务不适用于，并不应提供给在此类信息和服务的发布违反各自当地法律法规的国家或司法管辖区。 来自上述地区的访客应在使用我们的服务之前，确认您对我们的服务进行投资的决定是否符合您居住国家/地区的法规。 我们保留随时更改，修改或终止我们的产品和服务的权利。"])},
    "section_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户须知"])},
    "section_client_desc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议。 本网站上提供的任何评论，陈述，数据，信息，材料或第三方材料（“材料”）仅供参考。 该材料仅被认为是市场传播，不包含，也不应被解释为包含任何交易的投资建议和/或投资推荐。 尽管我们已尽一切合理的努力确保信息的准确性和完整性，但我们对材料不做任何陈述和保证，如果所提供信息的任何不准确和不完整，我们也不对任何损失负责，包括但不限于利润损失，直接或间接损失或损害赔偿。 未经我们的同意，您只能将该材料用于个人用途，不得复制，复制，重新分发和/或许可该材料。"])},
    "section_client_desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们使用我们网站上的cookies来根据您的喜好自定义我们网站上显示的信息和体验。 通过访问本网站，您承认您已经阅读并同意上述详细信息，并同意我们使用cookies。"])},
    "section_client_desc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们完全遵守司法管辖区中所有适用的法律和法规。 您有责任确定并确保您的投资符合您的要求。您承诺将承担投资和交易的所有后果。"])},
    "section_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo 实体公告"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
    "item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States的公司。"])},
    "item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc 是一家受美国证券交易委员会（US SEC）和金融业监管局 (US FINRA) 监管的经纪商，监管编号分别为 SEC: 8-41551和CRD: 24409。"])},
    "item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities 是Elish & Elish Inc 授权的商业名称。"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
    "item2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Berkeley Suite, 35 Berkeley Square, Mayfair, London, England, W1J 5BF 的公司， 公司注册编号为10684079。"])},
    "item2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited 是一家期货、外汇经纪商和流动性供应商，受英国金融行为监管局（UK FCA）授权和监管，监管编号为833414。"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
    "item3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Level 4, 28-34 O'Connell Street, Sydney NSW 2000 的公司， 澳大利亚公司编号为100 139 820。"])},
    "item3_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited 是持有澳大利亚金融服务牌照的主体，受澳大利亚证券与投资委员会（AU ASIC）授权和监管，监管编号为222650。"])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited"])},
    "item4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于Unit E, 30/F, Tower A, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong的公司，公司注册编号为2507030。"])},
    "item4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Wealth Management HK Limited是一家持牌保险经纪公司，受香港保险业监管局（HK IA）授权和监管，监管编号为FB1823。"])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
    "item5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的公司，公司注册编号为2169884。"])},
    "item5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited 是一家持牌货币服务运营商，受香港海关（HK C&ED）授权和监管，监管编号为18-06-02444。"])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
    "item6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的公司，公司注册编号为2601567。"])},
    "item6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited 是一家持牌公司服务供应商，受香港公司注册处（HK CR）授权和监管，监管编号为TC006709。"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
    "item7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles 的公司，公司注册编号为8427202-1。"])},
    "item7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited 是一家持牌证券交易商，受塞舌尔金融服务管理局（SC FSA）授权和监管，监管编号为SD090。"])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
    "item8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 The Cyberati Lounge, Ground Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebene, Republic of Mauritius 的公司, 公司注册编号为172054。"])},
    "item8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited 是一家持牌投资交易商，受毛里求斯金融服务委员会（MU FSC）授权和监管，监管编号为 C119023907。"])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited"])},
    "item9_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Govant Building, Po Box 1276, Port Vila, Vanuatu 的公司, 公司注册编号为700238。"])},
    "item9_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Vanuatu Limited 是一家持牌金融交易商，受瓦努阿图金融服务委员会（ VU FSC）授权和监管，监管编号为700238。"])}
  },
  "footer": {
    "contact": {
      "phoneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系电话："])},
      "phoneEurope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+44 11 3733 5199(欧洲)"])},
      "phoneAsia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+852 3704 4241(亚洲)"])},
      "phoneSingapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+65 6011 1415 (亚洲 - 新加坡)"])},
      "phoneChina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+86 400 8427 539(亚洲 - 中国地区)"])},
      "phoneVietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+84 28 4458 2674 (亚洲 - 越南)"])},
      "emailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱："])},
      "emailAccountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.sales", "@", "dooprime.com(客户经理)"])},
      "emailTechnicalSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cn.support", "@", "dooprime.com(技术支持)"])}
    },
    "menu": {
      "marketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场"])},
      "marketSecurities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证券"])},
      "marketSecuritiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/securities"])},
      "marketFutures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期货"])},
      "marketFuturesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/futures"])},
      "marketForex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币对"])},
      "marketForexUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/forex"])},
      "marketMetals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贵金属"])},
      "marketMetalsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/precious-metal"])},
      "marketCommodities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗商品"])},
      "marketCommoditiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/commodities"])},
      "marketStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股票指数"])},
      "marketStockUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market/stock-indices"])},
      "toolsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工具"])},
      "toolsPlatformTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易软件"])},
      "toolsPlatformMetaTrader4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTrader 4"])},
      "toolsPlatformMetaTrader4Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/metatrader4"])},
      "toolsPlatformMetaTrader5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTrader 5"])},
      "toolsPlatformMetaTrader5Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/metatrader5"])},
      "toolsPlatformMT4vsMT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MT4 vs MT5"])},
      "toolsPlatformMT4vsMT5Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/mt4-vs-mt5/"])},
      "toolsPlatformDooPrimeInTrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime InTrade"])},
      "toolsPlatformDooPrimeInTradeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/intrade"])},
      "toolsPlatformTradingView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TradingView"])},
      "toolsPlatformTradingViewUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/tradingview"])},
      "toolsPlatformFIXAPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIX API 4.4"])},
      "toolsPlatformFIXAPIUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-platform/fix-api"])},
      "toolsSocialTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社区化交易"])},
      "toolsSocialOutrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outrade"])},
      "toolsSocialOutradeUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/outrade"])},
      "toolsSocialMyfxbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myfxbook"])},
      "toolsSocialMyfxbookUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/myfxbook"])},
      "toolsSocialFOLLOWME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FOLLOWME"])},
      "toolsSocialFOLLOWMEUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/social-trading/followme"])},
      "toolsAnalysisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深度分析"])},
      "toolsAnalysisTradingCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Central"])},
      "toolsAnalysisTradingCentralUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market-analysis/trading-central"])},
      "toolsAnalysisEconomicCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财经日历"])},
      "toolsAnalysisEconomicCalendarUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/market-analysis/economic-calendar"])},
      "toolsTradingToolsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "toolsTradingToolsVPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VPS 交易专用服务器"])},
      "toolsTradingToolsVPSUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading-tools/vps"])},
      "tradingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
      "tradingTransactionOverviewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易概览"])},
      "tradingTransactionOverviewPricingModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价模型"])},
      "tradingTransactionOverviewPricingModelUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/pricing-model"])},
      "tradingTransactionOverviewTechnology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尖端科技"])},
      "tradingTransactionOverviewTechnologyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/technology"])},
      "tradingTransactionOverviewAccountsComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户对比"])},
      "tradingTransactionOverviewAccountsComparisonUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/account"])},
      "tradingTransactionOverviewFlexibleFunding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金存取"])},
      "tradingTransactionOverviewFlexibleFundingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/funding"])},
      "tradingConditionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易细则"])},
      "tradingConditionContractSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约规格"])},
      "tradingConditionContractSpecificationsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/contract-specification"])},
      "tradingConditionTradingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易日历"])},
      "tradingConditionTradingCalendarUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/trading-calendar"])},
      "tradingConditionLeverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杠杆倍数"])},
      "tradingConditionLeverageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/leverage"])},
      "tradingConditionOvernightInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隔夜利息"])},
      "tradingConditionOvernightInterestUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/overnight-interest"])},
      "tradingConditionDividend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股息调整"])},
      "tradingConditionDividendUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/trading/dividend"])},
      "partnershipTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广合作"])},
      "partnershipPromotionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精彩活动"])},
      "partnershipPromotionPointsMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["理想家积分商城"])},
      "partnershipPromotionPointsMallUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://dooprimerewards.com/mall"])},
      "partnershipPromotionDooTraderTradingCompetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DooTrader 交易大赛"])},
      "partnershipPromotionDooTraderTradingCompetitionUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dootrader.com/sc"])},
      "partnershipPartnersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
      "partnershipPartnersIntroducingBrokers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍经纪商"])},
      "partnershipPartnersIntroducingBrokersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://dp.dooprimeio.com/sc/partner/cooperation"])},
      "aboutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于 Doo Prime"])},
      "aboutOurStory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司介绍"])},
      "aboutOurStoryUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/aboutus"])},
      "aboutCorporateSocialResponsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企业社会责任"])},
      "aboutCorporateSocialResponsibilityUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/?#"])},
      "aboutMediaCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["媒体中心"])},
      "aboutMediaCenterUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimenew.com/"])},
      "aboutCompliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合规披露"])},
      "aboutComplianceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/compliance"])},
      "aboutContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])},
      "aboutContactUsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/contactus"])},
      "aboutCareers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招贤纳士"])},
      "aboutCareersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimeadw1.com/careers"])},
      "aboutHelpCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助中心"])},
      "aboutHelpCentreUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.dooprimehelp.com/sc/"])}
    },
    "compliance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合规披露"])},
      "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请仔细阅读以下合规披露："])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站由 Doo Prime Vanuatu Limited 拥有和运营。"])},
      "description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该网站可在全球范围内访问，并不特定于任何实体。这主要是为了信息集中展示和对比的便利，你的实际权利和义务将基于你所选择的实体从监管所获得的授权和监管所决定。"])},
      "description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须至少年满18岁，然后才能访问我们的网站产品和服务。 通过访问我们的网站，您确认您符合了年龄的要求。"])},
      "description5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有些当地法律法规禁止或限制您访问，下载，分发，传播，共享或以其他方式使用本网站上发布的任何或所有文档和信息的权利。"])}
    },
    "tab": {
      "productsRiskDisclosureLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高风险交易产品风险披露"])},
      "productsRiskDisclosureItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于基础金融工具的价值和价格会有剧烈变动，股票，证券，期货，差价合约和其他金融产品交易涉及高风险，可能会在短时间内发生超过您的初始投资的大额亏损。"])},
      "productsRiskDisclosureItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过去的投资表现并不代表其未来的表现。"])},
      "productsRiskDisclosureItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在与我们进行任何交易之前，请确保您完全了解使用相应金融工具进行交易的风险。 如果您不了解此处说明的风险，则应寻求独立的专业建议。"])},
      "brandAndEntityNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["品牌和实体公告"])},
      "brandAndEntityNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo Consulting 和Doo Exchange 是Doo Group 所拥有的商用名称、注册商标和知识产权。"])},
      "brandAndEntityNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有各种受监管和许可的实体提供相关的金融服务。 您可以与我们的任何实体建立业务关系。 所选择的实体意味着您的交易账户，交易活动和资金只由这特定的监管机构进行监管。"])},
      "brandAndEntityNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们不向某些司法管辖区的个人和公司提供服务，包括但不限于阿富汗、阿塞拜疆、波斯尼亚和黑塞哥维那、布隆迪、喀麦隆、加拿大、中非共和国、乍得、刚果（布拉柴维尔）、刚果（金沙萨）、古巴、赤道几内亚、厄立特里亚、埃塞俄比亚、几内亚比绍、圭亚那、海地、香港、伊朗、伊拉克、以色列、黎巴嫩、利比亚、马来西亚、马里、尼加拉瓜、朝鲜、新加坡、索马里、南苏丹、斯里兰卡、苏丹、叙利亚、塔吉克斯坦、特立尼达和多巴哥、突尼斯、土库曼斯坦、阿拉伯联合酋长国，美国，委内瑞拉，也门，津巴布韦。 我们网站上的信息和服务不适用于，并不应提供给在此类信息和服务的发布违反各自当地法律法规的国家或司法管辖区。 来自上述地区的访客应在使用我们的服务之前，确认您对我们的服务进行投资的决定是否符合您居住国家/地区的法规。 我们保留随时更改，修改或终止我们的产品和服务的权利。"])},
      "clientNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户须知"])},
      "clientNoticeItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处显示的任何交易符号仅用于说明目的，不构成我们的任何建议。 本网站上提供的任何评论，陈述，数据，信息，材料或第三方材料（“材料”）仅供参考。 该材料仅被认为是市场传播，不包含，也不应被解释为包含任何交易的投资建议和/或投资推荐。 尽管我们已尽一切合理的努力确保信息的准确性和完整性，但我们对材料不做任何陈述和保证，如果所提供信息的任何不准确和不完整，我们也不对任何损失负责，包括但不限于利润损失，直接或间接损失或损害赔偿。 未经我们的同意，您只能将该材料用于个人用途，不得复制，复制，重新分发和/或许可该材料。"])},
      "clientNoticeItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们使用我们网站上的cookies来根据您的喜好自定义我们网站上显示的信息和体验。 通过访问本网站，您承认您已经阅读并同意上述详细信息，并同意我们使用cookies。"])},
      "clientNoticeItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们完全遵守司法管辖区中所有适用的法律和法规。 您有责任确定并确保您的投资符合您的要求。您承诺将承担投资和交易的所有后果。"])},
      "dooEntitiesNoticeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo 实体公告"])},
      "dooEntitiesAddress1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc"])},
      "dooEntitiesAddress1Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于7700 Windrose Avenue, STE 300 Plano, Texas 75024, United States的公司。"])},
      "dooEntitiesAddress1Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elish & Elish Inc 是一家受美国证券交易委员会（US SEC）和金融业监管局 (FINRA) 监管的经纪商，监管编号分别为 SEC: 8-41551和CRD: 24409。"])},
      "dooEntitiesAddress1Item3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peter Elish Investments Securities 是Elish & Elish Inc 授权的商业名称。"])},
      "dooEntitiesAddress2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited"])},
      "dooEntitiesAddress2Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 208 Uxbridge Road, Shepherds Bush, London, England, W12 7JD 的有限责任公司， 公司注册编号为10684079。"])},
      "dooEntitiesAddress2Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Clearing Limited 是一家期货及外汇经纪商和流动性提供商，由英国金融行为监管局（UK FCA）授权和监管，监管编号为833414。"])},
      "dooEntitiesAddress3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited"])},
      "dooEntitiesAddress3Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Level 4, 28-34 O’Connell Street, Sydney NSW 2000 的公司， 澳大利亚公司编号为100 139 820。"])},
      "dooEntitiesAddress3Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Financial Australia Limited 是澳大利亚金融服务牌照持有主体，受澳大利亚证券与投资委员会监管，监管编号为222650"])},
      "dooEntitiesAddress4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited"])},
      "dooEntitiesAddress4Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的有限责任公司，公司注册编号为2601567。"])},
      "dooEntitiesAddress4Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Consulting Limited 是一家专业服务提供者，由香港特别行政区公司注册处授予信托及公司服务提供者（TCSP）牌照，牌照编号为TC006709。"])},
      "dooEntitiesAddress5Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited"])},
      "dooEntitiesAddress5Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong 的有限责任公司，公司注册编号为2169884。"])},
      "dooEntitiesAddress5Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Holding Group Limited 是一家持牌货币服务运营商，由香港特别行政区海关授权经营货币兑换及汇款业务，牌照编号为18-06-02444。"])},
      "dooEntitiesAddress6Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited"])},
      "dooEntitiesAddress6Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于 Lot 15 A3, 1st Floor, Cybercity, Ebene 72201, Mauritius 的有限责任公司, 公司号码为172054。"])},
      "dooEntitiesAddress6Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Mauritius Limited 是一家投资交易商，由毛里求斯金融服务委员会（FSC）授权和监管，监管编号为 C119023907。"])},
      "dooEntitiesAddress7Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited"])},
      "dooEntitiesAddress7Item1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是一家注册地址位于Room B11, 1st Floor Providence Complex, Providence, Mahe, Seychelles 的有限责任公司，公司注册编号为8427202-1。"])},
      "dooEntitiesAddress7Item2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doo Prime Seychelles Limited 是持牌证券交易商，受塞舌尔金融服务管理局（FSA）授权和监管，监管编号为SD090。"])}
    },
    "documents": {
      "ClientAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户协议"])},
      "AMLCTFPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML& CTF 政策"])},
      "ExecutionPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行政策"])},
      "RefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退款政策"])},
      "RiskAcknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风险披露声明"])},
      "PrivacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人隐私条例声明"])},
      "WebsiteTAndC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站条款细则"])},
      "Sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站地图"])}
    },
    "bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站由Doo Prime Vanuatu Limited (公司编号 700238) 持有和运营。© 2022 Doo Prime。版权所有。"])}
  }
}