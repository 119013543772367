<template>
    <footer id="footer">
        <FooterTop />
        <FooterMiddle />
        <FooterBottom />
    </footer>
</template>

<script>
    import FooterTop from '@/components/Footer/FooterTop.vue'
    import FooterMiddle from '@/components/Footer/FooterMiddle.vue'
    import FooterBottom from '@/components/Footer/FooterBottom.vue'
    export default {
        name: "Footer",
        components: {
            FooterTop,
            FooterMiddle,
            FooterBottom
        }
    }
</script>

<style lang="scss">
    #footer {
        text-align: left;
    }
</style>