<template>
    <div id="footer-bottom">
        <div class="footer-bottom-wrapper container">
        <p v-t="'footer.bottom'"></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterBottom"
    }
</script>

<style lang="scss">
    #footer-bottom {
        font-size: 14px;
        color: #ffffff;
        padding: 22px 0;
        background-color: #000018;
        text-align: center;
        height: 59px;
        @include mobile-screen() {
            padding: 27px 0;
            font-size: 14px;
            padding-left:26px;
            padding-right:24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 1.5px;
            color: #f5f5f5;
            height:120px;
            text-align:left;
            p {
                margin:0;
            }
        }
    }
</style>