<template>

  <router-view />
</template>

<script>
export default {
  methods: {
    async setLanguage() {
      this.$i18n.locale = 'sc'
      console.log(this.$i18n.locale, 'is current lang')
    },
  },
  mounted() {
      this.setLanguage();
    }
}
</script>


<style lnag="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* max-width:1920px; */
  overflow-x: hidden;
  /* width:100vw; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



</style>
